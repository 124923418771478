import React from 'react';
import classes from '../../scss/Staff.module.scss';
import StaffGroup from '../StaffGroup';
import Loader from '../Loader';
import type { StaffMember } from './types';

interface Props {
  filter: string
}

function Staff({ filter }: Props) {
  const [loading, setLoading]= React.useState(true)
  const [data, setData] = React.useState<StaffMember[]>([])
  const [error, setError] = React.useState(false)

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`https://admin.timboo.org/wp-json/wp/v2/staff?per_page=100`);
      const data = await response.json();

      if(response.ok) {
        setError(false)

        const filteredData = data.filter((item: StaffMember) => item.x_categories.includes(filter))
        setData(filteredData)
      } else {
        setError(true)
      }
      
      setLoading(false)
    }

    fetchData();
  }, [])
  

  if (loading) return <Loader/>;
  if (error) return <p>Error while loading data</p>;


  return <div className={ classes.Staff } id='timboo_staff'>
    <StaffGroup title={ 'test' } staff={ data } key={ 'l34dsmlgkfs51mglsda' }></StaffGroup>
  </div>
}

export default Staff;