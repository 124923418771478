import React from 'react';
import classes from '../scss/StaffGroup.module.scss';
import StaffGroupItem from './StaffGroupItem';
import type { StaffGroupData } from './Staff/types';

function StaffGroup({ staff }: StaffGroupData) {

  const staffMembersData = staff.map( (item, index ) => {
    return (
      <StaffGroupItem
        title={ item.title.rendered }
        content={ item.content.rendered }
        image={ item.x_featured_media_medium }
        imageLqip={ item.x_featured_media_lqip }
        key={index}
      ></StaffGroupItem>
    )
  });

  return <div className={ classes.Body }>
    <ul className={ classes.List }>
      { staffMembersData }
    </ul>
  </div>
}

export default StaffGroup;